<template>
  <div class="d-flex justify-space-between pt-2">
    <div>
      <v-btn
        :loading="loading"
        small
        color="primary"
        class="white--text px-4"
        @click="getDeptLoadXlsx"
      >
        <v-icon left size="20">mdi-file-download-outline</v-icon>В виде Excel
      </v-btn>
    </div>

    <v-btn
      text
      color="accent"
      :ripple="false"
      class="show-filter px-0"
      @click="$emit('setShowFilter')"
    >
      <v-icon left size="20">mdi-filter-outline</v-icon>
      Фильтры
      <v-icon left size="20" class="ml-1 mr-0">{{ showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { workloadApi } from '@/api'
import { mapGetters } from 'vuex'
import download from '@/plugins/download'

export default {
  name: 'EmployeesLoadButtons',

  props: {
    showFilter: Boolean,
    filters: Object
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters('department', ['departmentId'])
  },

  methods: {
    async getDeptLoadXlsx() {
      try {
        this.loading = true

        const body = {
          departmentId: this.departmentId,
          fromDate: this.filters.fromDate,
          toDate: this.filters.toDate
        }

        const data = await workloadApi.getDeptLoadXlsx(body)
        data && download(data, 'Нагрузка по кафедре.xlsx')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка формирования Excel файла')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>