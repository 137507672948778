<template>
  <v-row class="my-0">
    <v-col cols="12" class="py-0">
      <v-card class="table-card" :class="chipsHeight ? 'mt-3' : 'mt-4'">
        <v-card-title>
          <span class="accent--text">Нагрузка</span>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="tableSearch"
            class="mt-0 pt-0 table-search"
            append-icon="mdi-magnify"
            label="Поиск"
            single-line
            hide-details
            clearable
          />
        </v-card-title>

        <v-data-table
          ref="departmentLoadTable"
          class="base-table"
          loading-text="Загрузка..."
          fixed-header
          hide-default-footer
          :loading="tableLoading"
          :headers="headers"
          :items="departmentLoad"
          :search="tableSearch"
          :items-per-page="-1"
        >
          <template #item.index="{ item, index }">
            {{ index + 1 }}
          </template>

          <template #item.eduLvl="{ item }">
            <span v-if="item.eduLvl === 'Слушатель'" class="link" @click="goToCycleLoad">
              {{ item.eduLvl }}
            </span>

            <span v-else>{{ item.eduLvl }}</span>
          </template>

          <template slot="no-data">
            <div class="py-3">Нет нагрузки по кафедре</div>
          </template>

          <template slot="no-results">
            <div class="py-3">Ничего не найдено</div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DepartmentLoadTable',

  props: {
    tableLoading: Boolean,
    chipsHeight: Number,
    filtersHeight: Number,
    departmentLoad: {
      type: Array,
      required: true
    },
    filters: Object
  },

  mounted() {
    this.setTableHeight()
    window.addEventListener("resize", this.setTableHeight)
  },

  destroyed() {
    window.removeEventListener("resize", this.setTableHeight)
  },

  data: () => ({
    tableSearch: null,
    headers: [
      { text: '№', value: 'index', sortable: false, align: 'left' },
      { text: 'Уровень образования', value: 'eduLvl', sortable: false, align: 'left' },
      { text: 'Плановая учебная нагрузка', value: 'planHoursAX', sortable: false, align: 'center' },
      { text: 'Плановое расписание', value: 'planHours', sortable: false, align: 'center' },
      { text: 'Фактическое расписание', value: 'factHours', sortable: false, align: 'center' }
    ]
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),
    ...mapGetters('department', ['departmentId'])
  },

  methods: {
    goToCycleLoad() {
      const params = {
        filters: {
          fromDate: this.filters?.fromDate || null,
          toDate: this.filters?.toDate || null
        }
      }

      this.$router.push({ name: 'cyclesLoad', params })
    },

    setTableHeight() {
      const chipsHeight = this.chipsHeight ? this.chipsHeight + 12 : 16
      const filtersHeight = this.filtersHeight ? this.filtersHeight + 16 : 0
      // суммарная высота элементов на странице, кроме таблицы
      const otherElements = 334 + chipsHeight + filtersHeight + this.techMessageHeight

      if (this.$refs?.departmentLoadTable) {
        this.$refs.departmentLoadTable.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
      }
    }
  },

  watch: {
    chipsHeight() {
      this.setTableHeight()
    },

    filtersHeight() {
      this.setTableHeight()
    }
  }
}
</script>